import "./NoPageContent.css";

const NoPageContent = () => {
  return (
    <div className="no-page">
      <h1 className="section-title-h1">404</h1>
      <h1 className="section-title-h1">Page not found</h1>
      <div id="notfoundimage">
        <img src="cvlogoedit4png.png" alt="A curly brace with an eye" />
      </div>
    </div>
  );
};

export default NoPageContent;
