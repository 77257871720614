import NoPageContent from "../components/NoPage/NoPageContent";

const NoPage = () => {
  return (
    <div>
      <NoPageContent />
    </div>
  );
};

export default NoPage;
