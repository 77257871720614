import "./CreditsContent.css";
import React, { useEffect, useState, useRef } from "react";

const CreditsContent = () => {
  useEffect(() => {}, []);
  return (
    <div>
      <div className="contact-text-container">
        <p className="narrator-paragraph-text">
          On this website I've used several free resources, primarily in the form of images. Below you can see fair credit for each resource used. Click the links to check out the authors product
          page.
        </p>
      </div>
      <div className="image-credit-container">
        <h2 className="section-title-h3 no-separator">1. Images</h2>
        <div className="freepik-container">
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline "
              href="https://www.freepik.com/free-vector/hand-drawn-silhouette-set_58597579.htm#query=walking%20silhouette&position=1&from_view=search&track=ais&uuid=8699b30f-d995-4656-b804-76f66ae930a5"
            >
              Image{" "}
            </a>
            by Freepik
          </div>
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline "
              href="https://www.freepik.com/free-vector/desktop-smartphone-app-development_10276838.htm#fromView=search&page=1&position=0&uuid=e819d498-bb36-44d7-b22a-2e1cf71460d1"
            >
              Image{" "}
            </a>
            by Freepik
          </div>

          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-vector/hand-drawn-walking-silhouette-illustration_57429743.htm#query=walking%20silhouette&position=14&from_view=search&track=ais&uuid=8699b30f-d995-4656-b804-76f66ae930a5"
            >
              Image{" "}
            </a>
            by Freepik
          </div>
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-photo/programming-background-with-html-text_36238366.htm#fromView=search&page=1&position=34&uuid=e98bfd09-48c0-4460-80fb-7386f9d33eac"
            >
              Image{" "}
            </a>
            by Freepik
          </div>
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-ai-image/glowing-blue-sphere-held-by-human-hand-generated-by-ai_42883647.htm#query=technologic&position=4&from_view=search&track=sph&uuid=90216101-327d-4860-8d98-3abfacf277c6"
            >
              Image{" "}
            </a>
            by Freepik
          </div>
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-vector/flat-design-soccer-player-silhouette_28462503.htm#query=football%20kick%20silhouette&position=12&from_view=search&track=ais&uuid=0544677e-5f26-4f6d-9509-95a15dae730f"
            >
              Image{" "}
            </a>
            by Freepik
          </div>
        </div>
        <div className="storyset-container">
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-vector/business-decisions-concept-illustration_11140496.htm#query=choosing%20direction&position=41&from_view=search&track=ais&uuid=528a6e5b-b76c-4f32-b199-0144c83d123f"
            >
              Image
            </a>{" "}
            by storyset on Freepik
          </div>
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-vector/before-dawn-concept-illustration_8392613.htm#query=man%20dawn%20@storyset&position=13&from_view=search&track=ais&uuid=30913148-aaa3-44b4-918f-2f191082ec94"
            >
              Image
            </a>{" "}
            by storyset on Freepik
          </div>
        </div>
        <div className="rawpixel-container">
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-vector/5g-networking-technology-background-with-blue-digital-line_16406299.htm#page=2&query=technology&position=22&from_view=search&track=sph&uuid=6080f49b-5171-4195-8a91-cd1f4b420def"
            >
              Image
            </a>{" "}
            by rawpixel.com on Freepik
          </div>

          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-photo/researcher-using-transparent-digital-tablet-screen-futuristic-technology_15434073.htm#page=6&query=technologic&position=38&from_view=search&track=sph&uuid=f5e9bddb-0e8d-43ad-81f2-79b721d43e12"
            >
              Image
            </a>{" "}
            by rawpixel.com on Freepik
          </div>
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-vector/data-protection-background-vector-cyber-security-technology-purple-tone_18236485.htm#query=technology%20safety&position=2&from_view=search&track=ais&uuid=806191ab-18ed-4cd4-9874-92394d49fd8f"
            >
              Image
            </a>{" "}
            by rawpixel.com on Freepik
          </div>
        </div>
        <div className="various-creators-container">
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-vector/cute-man-working-computer-with-coffee-cartoon-vector-icon-illustration-people-technology-icon-concept-isolated-premium-vector-flat-cartoon-style_20188201.htm#fromView=search&page=1&position=8&uuid=be9a99b3-c2b2-4c24-8528-3f12cc212a05"
            >
              Image
            </a>{" "}
            by catalyststuff on Freepik
          </div>
        </div>

        <div className="various-creators-container">
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-vector/silhouette-style-kids-dream-logo-illustration-vector_33683945.htm#query=child%20star%20silhouette&position=0&from_view=search&track=ais&uuid=4976e4d4-2acb-4c75-972c-c14618b0eff1"
            >
              Image
            </a>{" "}
            by soepratman on Freepik
          </div>
          <div className="various-creators-container">
            <div className="credits-link-div bread-text">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="word-highlight-pink links-no-underline"
                href="https://www.freepik.com/free-photo/book-that-has-word-mountain-it_41073765.htm#fromView=search&page=1&position=3&uuid=77fe824c-609c-4251-bd38-d38917b941ca"
              >
                Image
              </a>{" "}
              by vecstock on Freepik
            </div>
          </div>
          <div className="various-creators-container">
            <div className="credits-link-div bread-text">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="word-highlight-pink links-no-underline"
                href="https://www.freepik.com/free-vector/business-processes-organization-workforce-distribution-delegating-responsibilities-scheduling-planning-managerial-supervision-control-vector-isolated-concept-metaphor-illustration_12083364.htm#query=work%20flow&position=19&from_view=search&track=ais&uuid=2fa58e07-ecef-4a0a-8118-df247b425e21"
              >
                Image
              </a>{" "}
              by vectorjuice on Freepik
            </div>
          </div>
        </div>
        <div className="various-creators-container">
          <div className="credits-link-div bread-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="word-highlight-pink links-no-underline"
              href="https://www.freepik.com/free-vector/light-bulb-energy-saving-lamp-glasses-mind-thinking-solution-abstract-low-poly-wireframe-mesh-design-from-connecting-dot-line-vector-illustration-blue-background_24058349.htm#page=2&query=technology%20inspiration&position=3&from_view=search&track=ais&uuid=59339b29-4fb4-4aae-b239-0bf9c8e35fbe"
            >
              Image
            </a>{" "}
            by WangXiNa on Freepik
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreditsContent;
